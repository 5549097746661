import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import cvInformations from "../../localization/fr.json"
import { withTranslation } from "react-i18next"
import "./style.scss"

class TabsTwo extends Component {
  renderInformation = (label) => {
    const { t } = this.props
    const title = `${label}_title`
    const value = `${label}_value`
    const year = `${label}_year`

    return Object.keys(cvInformations).map((element) => {
      if (element === "experience_title_1") return null

      if (element.indexOf(title) > -1) {
        const labelValue = value + element.substring(element.lastIndexOf("_"))
        const yearValue = year + element.substring(element.lastIndexOf("_"))
        if (labelValue && t(labelValue)) {
          return (
            <li key={element}>
              <span className="about-section-title">
                {t(element)}
                <span className="about-section-value"> { label.includes('exprience') || label.includes('education') ? <br/> : " - " }{t(labelValue)}</span>
              </span>
              {year && cvInformations[yearValue] && t(yearValue)}
            </li>
          )
        }
        return null
      } else {
        return null
      }
    })
  }

  renderExperienceValues = (t) => {
    const experienceList = []
  
    // Iterate dynamically over the possible experience value list keys (experience_value_list_*)
    let index = 1
    while (true) {
      const translatedValue = t(`experience_value_list_${index}`, { defaultValue: `experience_value_list_${index}` })
      
      // If the translation doesn't exist, break the loop
      if (translatedValue === `experience_value_list_${index}`) {
        break
      }
  
      experienceList.push(
        <li key={`experience_value_list_${index}`}>{translatedValue}</li>
      )
      index++
    }
  
    return experienceList
  }

  render() {
    const { t } = this.props
    const tab1 = t("mainSkills")
    const tab2 = t("education")
    const tab3 = t("experience")
    const tab4 = t("certification")
    const { tabStyle } = this.props

    const skillsInformations = this.renderInformation("skills")
    const educationInformations = this.renderInformation("education")
    const experienceInformations = this.renderInformation("experience").slice(1)
    const certificationInformations = this.renderInformation("certification")

    const experienceValueList = this.renderExperienceValues(t)

    return (
      <div>
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>{skillsInformations}</ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>{educationInformations}</ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <span className="about-section-title">
                            {t("experience_title_1")}
                            <span className="about-section-value">
                              - Projects:
                              <ul>{experienceValueList}</ul>
                            </span>
                          </span>
                          {t("experience_year_1")}
                        </li>
                        {experienceInformations}
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>{certificationInformations}</ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(TabsTwo)
