import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import { withTranslation } from 'react-i18next';
import PortfolioDetails from "../elements/PortfolioDetails";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

class PortfolioLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProjectDetails: false,
            details: null
        }
    }

    toggleGlobalState = (details) => {
        this.setState({
            showProjectDetails: true,
            details
        })
    }
    updateGlobalSate = () => {
        this.setState({
            showProjectDetails: false,
            details: null
        })
    }

render() {
    const { t } = this.props
    const { details, showProjectDetails } = this.state

    if (showProjectDetails)
        return <PortfolioDetails updateGlobalSate={this.updateGlobalSate} details={details} />

    return (
        <div className="active-dark">
            <Helmet pageTitle="Sami GHORBEL" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" updateGlobalSate={this.updateGlobalSate}/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{t('category')}</span> : ''}
                                            <h1 className="title">{t('presentation')}<br/>
                                            <TextLoop>
                                                
                                                <span>{t('infoPresentation1')}</span>
                                                <span>{t('infoPresentation2')}</span>
                                                {/* <span> .</span> */}
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2>{t('basedPresentation')}</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                {/* <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/sami/sami_ghorbel_1.jpg" alt="About Images"/>
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-7"> */}
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{t('aboutMe')}</h2>
                                            <p className="description">{t('infoAboutMe')}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Portfolio Area */}
            {/* <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">{t('my_projects')}</h2>
                                        <p>{t('my_projects_description')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" toggleGlobalState={this.toggleGlobalState}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Portfolio Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactTitle={t('hire_txt')} />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}
}

export default withTranslation() (PortfolioLanding);
